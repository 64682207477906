.navBar {
  text-align: center;
  width: 100%;
}
#sharlenemay {
  font-weight: 700;
  /* text-align: center;
  width: 100%; */
}
/* .navLink {
  font-family: "Times New Roman", Times, serif;
  color: #000000 !important;
  padding: 1rem 5rem;
  text-decoration: none;
  text-align: center;
  width: 100%;
} */
.navLinks {
  font-family: "Times New Roman", Times, serif;
  /* font-family: 'Newsreader', serif; */
  color: #000000 !important;
  padding: 1rem;
  text-decoration: none;
  /* text-align: center;
  width: 100%; */
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: underline !important;
}

hr {
    margin-top: 0 !important;
    color: black;
}